const nav = document.getElementById("nav_mobile_button");
const links = [...document.getElementById("nav").children];
var navClasses = nav.classList;

var active = navClasses.contains("nav_mobile_button_active");

nav.addEventListener("click", () => {
  if (active) {
    navClasses.remove("nav_mobile_button_active");
  } else {
    navClasses.add("nav_mobile_button_active");
  }
  active = !active;
});

links.forEach((link, index) => {
  link.addEventListener("click", () => {
    navClasses.remove("nav_mobile_button_active");
  });
});
