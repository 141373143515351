const nav = document.getElementById("nav");
const main = document.getElementById("main");
const links = [...nav.children];
const headers = [...document.getElementsByClassName("header")];

const pages = [];

const navigate = index => {
  main.style.transform = "translateX(-"+index+"00vw)";
  resetNav(index);
  resetHeaders(index);
};

const resetNav = index => {
  links.forEach(link => {
    link.classList.remove("nav_active");
  });
  links[index].classList.add("nav_active");
};

const resetHeaders = index => {
  headers.forEach(header => {
    header.classList.remove("header_active");
  });
  headers[index].classList.add("header_active");
};

links.forEach((link, index) => {
  var pageTitle = link.children[0].innerHTML;

  pages.push(pageTitle);

  link.addEventListener("click", () => {
    document.title = "Ionado: " + pageTitle.toUpperCase();
    history.pushState(
      {},
      "Ionado: " + pageTitle.toUpperCase(),
      window.location.origin + "#" + pageTitle
    );

    navigate(index);
  });
});

urlListener = () => {
  var index = pages.indexOf(decodeURI(window.location.hash).slice(1));
  if (!window.location.hash) {
    index = 0;
  }
  navigate(index);
};

window.onload = () => {
  urlListener();
};

window.onpopstate = () => {
  urlListener();
};

window.onpushstate = () => {
  urlListener();
};
